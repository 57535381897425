<template>

	<div>
		<TopNav></TopNav>
		<Header></Header>
			
		<div class="Wrap">
			<div class="UrHere">
				<i class="el-icon-s-home" @click="$Jump('/')" style="cursor: pointer;"> 首页</i> > 资讯中心
			</div>
		</div>
		<div class="Wrap">
		
			
			<div class="NewsList">
				
				<div class="NewsDetail">
					<h1>{{News.Title}}</h1>
					<div class="Thumb" v-if="News.Thumb && News.Thumb.length > 0">
						<img :src="News.Thumb" />
					</div>
					<div class="Content" v-html="News.Detail">
					</div>
					<div class="Time">
						{{News.CreatedAt}} 发布于 {{News.CategoryName}} 频道
					</div>
				</div>
				
			</div>
			
			<div class="NewsCategory">
				<li @click="$Jump('/news/list/all')">全部资讯</li>
				<template v-for="cat in NewsCategoryList">
					<li :class="News.CategoryId == cat.Id ? 'On':''" @click="$Jump('/news/list/'+cat.Id)" v-if="cat.Id != '655df3db-66c6-48b1-8430-5f79f3757eae' && cat.Id != '864d1853-e2a5-450f-a582-8d9ca05f486d'" :key="cat.Id">{{cat.Name}}</li>
				</template>
			</div>
			
		</div>
		
	
		<Footer></Footer>
	</div>
	
</template>


<script>
	
	export default {
	  name: 'NewsInfo',
	  props: {
	  },
	  data() {
	      return {
			  News:{
				  Id:'',
				  Title:'',
				  Detail:'',
				  Thumb:'',
				  CreatedAt:'',
				  CategoryId:'',
				  CategoryName:'',
			  },
			  NewsCategoryList:[],
			  NewsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
	  },
	  created() {
		  this.News.Id = this.$route.params.Id
			this.GetNews()
			this.GetCategoryList()
	  },
	  methods:{
		  GetNews(){
		  		let data = {
		  			Service:'News',
		  			Class: 'News',
		  			Action: 'Get',
		  			Id:this.News.Id
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.News = res.Data
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  },
		  GetCategoryList(){
		  			let data = {
		  				Service:'News',
		  				Class: 'NewsCategory',
		  				Action: 'List',
		  				Page:1,
		  				PageSize:10,
		  			}
		  			this.$post(this.$store.getters.getApiHost,data)
		  			.then((res) => {
		  							
		  				if(res.ErrorId != 0){
		  					this.$message({ message: res.Msg, type: 'error'});
		  					return
		  				}
		  				if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  				this.NewsCategoryList = res.Data.NewsCategoryList
		  				
		  			})
		  }
	  }
	}
</script>

<style scoped>
.UrHere{
	margin: 20px 0px;
}
.NewsList{
	flex: 1;
	margin-right: 40px;
	background-color: #FFFFFF;
	margin-bottom: 40px;
}
.NewsDetail{
	padding: 20px 50px 50px 50px;
	max-width: 800px;
}
.NewsDetail .Time{
	color: rgba(0,0,0,0.4);
	margin: 20px 0px;
	text-align: right;
}
.NewsCategory{
	padding: 20px;
	background-color: #FFFFFF;
		margin-bottom: 40px;
}
.NewsList .Zero{
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
}
.NewsCategory li{
	padding: 10px 30px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	cursor: pointer;
}
.NewsCategory li:hover,.NewsCategory li.On{
	color: rgba(228,0,0,1);
}
</style>

